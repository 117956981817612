import { RouterOutput, trpcSwr } from '../../../api/trpc';

export const useAddCardPoizonItem = (state?: {
  productId: string;
  modificationId: string;
}) => {
  if (!state) {
    return { data: null };
  }

  const { data, isLoading } = trpcSwr.productCardPoizonAdmin.itemInfo.useSWR({
    productId: state.productId,
    modificationId: state.modificationId,
  });

  return { data, isLoading };
};
