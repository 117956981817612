import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { AlertService } from '../../../services/alert.service';
import { ModalsService } from '../../../services/modals.service';
import useSWR, { mutate } from 'swr';
import { api, fetcher } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { decimalValidation, required } from '../../../utils/validations';
import { ConfirmModal } from '../../common/confirm-modal';
import { PurchaseOrderActualFinancesRefundModal } from './purchase-order-actual-finances-refund-modal';
import { getCurrencySymbol, toMoney } from '../../../utils/common.utils';
import { ConfirmWithCommentsModal } from '../../common/ConfirmWithCommentsModal';
import { ConfirmWithPrivateCommentModal } from '../../common/ConfirmWithPrivateCommentModal';
import { PurchaseOrderFinancesActions } from './purchase-order-finances-actions';
import { Rbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Decimal } from 'decimal.js';
import { OrderFinancesAdditional } from './purchase-order-finances-additional-actions';
import { OrderAdditionalServiceModal } from './purchase-order-additional-service-modal';
import { useGlobalMutate } from '../../../api/useGlobalMutate';

type PurchaseOrder = {
  id: string;
  status:
    | 'waitingForPayment'
    | 'inWork'
    | 'onTheWayToStock'
    | 'receivedInStock'
    | 'sentToRussia'
    | 'canceled';
  productPrice: string;
  deliveryPrice: string;
  serviceCharge: string;
  moderateServiceCharge: string;
  orderMarkup: string;
  preMarkup: string;
  price: string;
  moderateProductPrice: string | null;
  moderateDeliveryPrice: string | null;
  moderateFinalPrice: string | null;
  currency?: string;
  expenses?: {
    client: string;
    company: string;
  };
};

type PurchaseOrderFinancesInfoProps = {
  id: string;
};

function ClientExpensesTable(props: {
  total?: string;
  actual?: string;
  currency: string;
}) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>К оплате клиенту</td>
          <td className="text-end">
            {props.total ? (
              toMoney(props.total, props.currency)
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <div>Итого оплачено клиентом</div>
          </td>
          <td className="text-end">
            {props.actual ? (
              toMoney(props.actual, props.currency)
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CompanyExpensesTable(props: {
  total?: string;
  actual?: string;
  currency: string;
  serviceCharge?: string;
  moderateServiceCharge?: string;
}) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>
            <div>Фактические затраты</div>
            <div className="fw-400 color-gray-400">
              на товары и доставку по Китаю
            </div>
          </td>
          <td className="text-end">
            {props.total ? (
              toMoney(
                Number(props.total) + Number(props.moderateServiceCharge),
                props.currency,
              )
            ) : props.moderateServiceCharge ? (
              toMoney(Number(props.moderateServiceCharge), props.currency)
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <div>Фактических затрат оплачено</div>
          </td>
          <td className="text-end">
            {props.actual ? (
              toMoney(Number(props.actual), props.currency)
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CompanyProfitTable(props: { order: PurchaseOrder }) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>Прибыль по заказу</td>
          <td className="text-end">
            {props.order.moderateFinalPrice &&
            props.order.moderateFinalPrice !== '0' ? (
              toMoney(
                Number(props.order.price) -
                  Number(props.order.moderateFinalPrice) +
                  Number(props.order.serviceCharge) -
                  Number(props.order.moderateServiceCharge),
                props.order.currency ?? 'cny',
              )
            ) : props.order.serviceCharge &&
              props.order.serviceCharge !== '0' ? (
              toMoney(
                Number(props.order.serviceCharge) -
                  Number(props.order.moderateServiceCharge),
                props.order.currency ?? 'cny',
              )
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function AdditionalService(props: { order: PurchaseOrder }) {
  const threeDots = <i className="bi bi-three-dots color-gray-400" />;
  return (
    <table className="table table-xs table-borderless fw-bold align-middle">
      <tbody>
        <tr>
          <td>Дополнительные расходы</td>
          <td className="text-end">
            {props.order.serviceCharge && props.order.serviceCharge !== '0'
              ? toMoney(
                  props.order.serviceCharge,
                  props.order.currency ?? 'cny',
                )
              : threeDots}
          </td>
          <td className="text-end">
            {props.order.moderateServiceCharge &&
            props.order.moderateServiceCharge !== '0'
              ? toMoney(
                  props.order.moderateServiceCharge,
                  props.order.currency ?? 'cny',
                )
              : threeDots}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export const PurchaseOrderFinancesInfo: React.FC<
  PurchaseOrderFinancesInfoProps
> = ({ id }) => {
  const { data: order, mutate: mutateOrder } = useSWR<PurchaseOrder>(
    `/purchase-order/${id}`,
    fetcher,
  );
  const { mutate } = useGlobalMutate();

  const [editModeAdditionalService, setEditModeAdditionalService] =
    React.useState<'client' | 'company' | null>(null);

  useEffect(() => {
    if (editModeAdditionalService && order) {
      ModalsService.createModal(OrderAdditionalServiceModal, {
        side: editModeAdditionalService,
        orderId: order?.id,
        orderType: 'purchase-order',
        currency: order.currency,
        setEditModeAdditionalService: setEditModeAdditionalService,
      }).then(() => mutate());
    }
  }, [editModeAdditionalService]);

  function handleEditAdditional(type: 'client' | 'company') {
    setEditModeAdditionalService(type);
  }

  function handleCancelEdit() {
    setEditMode(null);
  }

  const [editMode, setEditMode] = React.useState<'client' | 'actual' | null>(
    null,
  );

  const threeDots = <i className="bi bi-three-dots color-gray-400" />;

  function handleEdit(type: 'client' | 'actual') {
    setEditMode(type);
  }

  function handleSave(data: PurchaseOrder) {
    mutateOrder(data);
    setEditMode(null);
  }

  if (!order) {
    return null;
  }

  switch (editMode) {
    case 'client':
      return (
        <PurchaseOrderClientFinancesForm
          id={id}
          currency={order.currency ?? 'cny'}
          defaultValues={{
            productPrice: order.productPrice,
            orderMarkup: order.orderMarkup,
            deliveryPrice: order.deliveryPrice,
          }}
          onSave={handleSave}
          onCancel={handleCancelEdit}
        />
      );
    case 'actual':
      return (
        <PurchaseOrderActualFinancesForm
          id={id}
          currency={order.currency ?? 'cny'}
          defaultValues={{
            moderateProductPrice: order.moderateProductPrice || undefined,
            moderateDeliveryPrice: order.moderateDeliveryPrice || undefined,
          }}
          onSave={handleSave}
          onCancel={handleCancelEdit}
        />
      );
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Финансы</div>
        {!['canceled', 'waitingForPayment'].includes(order.status) && (
          // @ts-expect-error order instanceof CleanPurchaseOrder
          <PurchaseOrderFinancesActions order={order} onEdit={handleEdit} />
        )}
      </div>
      <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
        <thead>
          <tr>
            <th className="color-gray-400">Позиция</th>
            <th className="color-gray-400 text-end">Клиент</th>
            <th className="color-gray-400 text-end">Факт</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Товар</td>
            <td className="text-end">
              {toMoney(order.productPrice, order.currency ?? 'cny')}
            </td>
            <td className="text-end">
              {order.moderateProductPrice
                ? toMoney(order.moderateProductPrice, order.currency ?? 'cny')
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>Комиссия за выкуп</td>
            <td className="text-end">
              {order.status === 'waitingForPayment'
                ? toMoney(order.preMarkup, order.currency ?? 'cny')
                : toMoney(order.orderMarkup, order.currency ?? 'cny')}
            </td>
          </tr>
          <tr>
            <td>Доставка товаров по Китаю</td>
            <td className="text-end">
              {toMoney(order.deliveryPrice, order.currency ?? 'cny')}
            </td>
            <td className="text-end">
              {order.moderateDeliveryPrice
                ? toMoney(order.moderateDeliveryPrice, order.currency ?? 'cny')
                : threeDots}
            </td>
          </tr>
        </tbody>
      </table>

      <Rbac
        object={RbacObject.Transaction}
        action={['write:additional-service']}
      >
        <div className="d-flex justify-content-end">
          <div>
            <OrderFinancesAdditional
              onEdit={handleEditAdditional}
              orderType={'purchase-order'}
            />
          </div>
        </div>
      </Rbac>
      <AdditionalService order={order} />
      <hr className="mt-3" />
      <ClientExpensesTable
        total={
          order.serviceCharge
            ? new Decimal(order.price).add(order.serviceCharge).toFixed()
            : '0'
        }
        actual={order.expenses?.client}
        currency={order.currency ?? 'cny'}
      />

      <Rbac object={RbacObject.Purchase} action={'read:finances-company'}>
        <hr className="mt-3" />
        <CompanyExpensesTable
          total={order.moderateFinalPrice ?? undefined}
          actual={order.expenses?.company}
          currency={order.currency ?? 'cny'}
          serviceCharge={order.serviceCharge}
          moderateServiceCharge={order.moderateServiceCharge}
        />
        <hr className="mt-3 mb-3" />
        <CompanyProfitTable order={order} />
      </Rbac>
    </div>
  );
};

type PurchaseOrderClientFinancesFormProps = {
  id: string;
  currency: string;
  defaultValues: {
    productPrice: string;
    orderMarkup?: string;
    deliveryPrice: string;
  };
  onSave: (data: PurchaseOrder) => void;
  onCancel: () => void;
};

async function updateClientFinances(
  id: string,
  currency: string,
  data: {
    productPrice: string;
    orderMarkup?: string;
    deliveryPrice: string;
    makeTransaction?: boolean;
    allowOverdraft?: boolean;
  },
): Promise<any> {
  return api
    .put(`/purchase-order/${id}/client-finances`, data)
    .catch((error) => {
      if (!error.response) throw error;

      if (
        error.response.status !== 409 ||
        !error.response.data ||
        !error.response.data.code
      ) {
        throw error;
      }

      switch (error.response.data.code) {
        case 'need-make-transaction': {
          const amount = Number(error.response.data.data.amount);
          return ModalsService.createModal(ConfirmWithCommentsModal, {
            title:
              amount < 0
                ? 'Итоговая сумма заказа больше оплаченной клиентом'
                : 'Итоговая сумма заказа меньше оплаченной клиентом',
            buttonText:
              amount < 0
                ? `Списать у клиента ${toMoney(Math.abs(amount), currency)}`
                : `Вернуть клиенту ${toMoney(Math.abs(amount), currency)}`,
          }).then((form) =>
            updateClientFinances(id, currency, {
              ...data,
              makeTransaction: true,
              ...form,
            }),
          );
        }
        case 'need-allow-overdraft': {
          return ModalsService.createModal(ConfirmModal, {
            title:
              'На счете клиента недостаточно средств для оплаты выбранного заказа',
            buttonText: `Оплатить заказ в долг`,
          }).then(() =>
            updateClientFinances(id, currency, {
              ...data,
              allowOverdraft: true,
            }),
          );
        }
        default:
          AlertService.error('Неизвестная ошибка');
          throw error;
      }
    });
}

const PurchaseOrderClientFinancesForm: React.FC<
  PurchaseOrderClientFinancesFormProps
> = (props) => {
  const { data: order } = useSWR<PurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );

  const { register, formState, handleSubmit, watch } = useForm<{
    productPrice: string;
    orderMarkup?: string;
    deliveryPrice: string;
  }>({
    defaultValues: props.defaultValues,
    mode: 'onChange',
  });

  const [productPrice, orderMarkup, deliveryPrice] = watch([
    'productPrice',
    'orderMarkup',
    'deliveryPrice',
  ]);

  const [debouncedCalculationData, setDebouncedCalculationData] =
    React.useState({
      productPrice,
      orderMarkup,
      deliveryPrice,
    });

  React.useEffect(() => {
    if (Object.keys(formState.errors).length) return;

    const handler = setTimeout(() => {
      setDebouncedCalculationData({
        productPrice,
        orderMarkup,
        deliveryPrice,
      });
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [formState, productPrice, orderMarkup, deliveryPrice]);

  const { data: calculation } = useSWR<{ total: string }>(
    {
      url: '/purchase-order/calculate-client-total',
      params: debouncedCalculationData,
    },
    fetcher,
  );

  const onSubmit = handleSubmit(async (data) => {
    const response = await updateClientFinances(props.id, props.currency, {
      productPrice: data.productPrice,
      orderMarkup: data.orderMarkup,
      deliveryPrice: data.deliveryPrice,
    });

    AlertService.success();
    props.onSave(response.data);
  });

  if (!order) {
    return null;
  }

  const threeDots = <i className="bi bi-three-dots color-gray-400" />;

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Финансы</div>
        <div>
          <button className="btn btn-link text-decoration-none">
            Сохранить
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            onClick={handleCancel}
          >
            Отменить
          </button>
        </div>
      </div>
      <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
        <thead>
          <tr>
            <th className="color-gray-400">Позиция</th>
            <th className="color-gray-400 text-end">Клиент</th>
            <th className="color-gray-400 text-end">Факт</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Товар</td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.productPrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('productPrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">
                    {getCurrencySymbol(order.currency ?? 'cny')}
                  </span>
                </div>
              </InputWrapper>
            </td>
            <td className="text-end">
              {order.moderateProductPrice
                ? toMoney(order.moderateProductPrice, order.currency ?? 'cny')
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>Комиссия за выкуп</td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.orderMarkup?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('orderMarkup', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">
                    {getCurrencySymbol(order.currency ?? 'cny')}
                  </span>
                </div>
              </InputWrapper>
            </td>
          </tr>
          <tr>
            <td>Доставка товаров по Китаю</td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.deliveryPrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('deliveryPrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">
                    {getCurrencySymbol(order.currency ?? 'cny')}
                  </span>
                </div>
              </InputWrapper>
            </td>
            <td className="text-end">
              {order.moderateDeliveryPrice
                ? toMoney(order.moderateDeliveryPrice, order.currency ?? 'cny')
                : threeDots}
            </td>
          </tr>
        </tbody>
      </table>

      <AdditionalService order={order} />

      <hr className="mt-3" />
      <ClientExpensesTable
        total={calculation?.total}
        actual={order.expenses?.client}
        currency={order.currency ?? 'cny'}
      />
      <hr className="mt-3" />
      <CompanyExpensesTable
        total={order.moderateFinalPrice ?? undefined}
        actual={order.expenses?.company}
        currency={order.currency ?? 'cny'}
        serviceCharge={order.serviceCharge}
        moderateServiceCharge={order.moderateServiceCharge}
      />
      <hr className="mt-3 mb-3" />
      <CompanyProfitTable order={order} />
    </form>
  );
};

async function updateActualFinances(
  id: string,
  currency: string,
  data: {
    moderateProductPrice: string;
    moderateDeliveryPrice: string;
    makeTransaction?: boolean;
    refundAmount?: string;
  },
): Promise<any> {
  return api
    .put(`/purchase-order/${id}/actual-finances`, data)
    .catch((error) => {
      if (!error.response) throw error;

      if (
        error.response.status !== 409 ||
        !error.response.data ||
        !error.response.data.code
      ) {
        throw error;
      }

      switch (error.response.data.code) {
        case 'need-make-transaction': {
          return Promise.resolve(Number(error.response.data.data.amount))
            .then((amount) => {
              if (amount < 0) {
                return ModalsService.createModal(
                  ConfirmWithPrivateCommentModal,
                  {
                    title: 'Итоговая сумма затрат больше оплаченной',
                    buttonText: `Списать с баланса ${toMoney(
                      Math.abs(amount),
                      currency,
                    )}`,
                  },
                );
              }

              return ModalsService.createModal(
                PurchaseOrderActualFinancesRefundModal,
                {
                  defaultValues: {
                    refundAmount: Math.abs(amount).toString(),
                  },
                },
              );
            })
            .then((result) =>
              updateActualFinances(id, currency, {
                ...data,
                ...result,
                makeTransaction: true,
              }),
            );
        }
        default:
          AlertService.error('Неизвестная ошибка');
          throw error;
      }
    });
}

type PurchaseOrderActualFinancesFormProps = {
  id: string;
  currency: string;
  defaultValues: {
    moderateProductPrice?: string;
    moderateDeliveryPrice?: string;
  };
  onSave: (data: PurchaseOrder) => void;
  onCancel: () => void;
};

const PurchaseOrderActualFinancesForm: React.FC<
  PurchaseOrderActualFinancesFormProps
> = (props) => {
  const { data: order } = useSWR<PurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );
  const threeDots = <i className="bi bi-three-dots color-gray-400" />;

  const { register, formState, handleSubmit, watch } = useForm<{
    moderateProductPrice: string;
    moderateDeliveryPrice: string;
  }>({
    defaultValues: props.defaultValues,
    mode: 'onChange',
  });

  const [moderateProductPrice, moderateDeliveryPrice] = watch([
    'moderateProductPrice',
    'moderateDeliveryPrice',
  ]);
  const [debouncedCalculationData, setDebouncedCalculationData] =
    React.useState({
      moderateProductPrice,
      moderateDeliveryPrice,
    });

  React.useEffect(() => {
    if (Object.keys(formState.errors).length) return;

    const handler = setTimeout(() => {
      setDebouncedCalculationData({
        moderateProductPrice,
        moderateDeliveryPrice,
      });
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [formState, moderateProductPrice, moderateDeliveryPrice]);

  const { data: calculation } = useSWR<{ total: string }>(
    {
      url: '/purchase-order/calculate-actual-total',
      params: debouncedCalculationData,
    },
    fetcher,
  );

  const onSubmit = handleSubmit(async (data) => {
    const response = await updateActualFinances(props.id, props.currency, {
      moderateProductPrice: data.moderateProductPrice,
      moderateDeliveryPrice: data.moderateDeliveryPrice,
    });

    AlertService.success();
    props.onSave(response.data);
  });

  if (!order) {
    return null;
  }

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Финансы</div>
        <div>
          <button className="btn btn-link text-decoration-none">
            Сохранить
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            onClick={handleCancel}
          >
            Отменить
          </button>
        </div>
      </div>
      <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
        <thead>
          <tr>
            <th className="color-gray-400">Позиция</th>
            <th className="color-gray-400 text-end">Клиент</th>
            <th className="color-gray-400 text-end">Факт</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Товар</td>
            <td className="text-end">
              {toMoney(order.productPrice, order.currency ?? 'cny')}
            </td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.moderateProductPrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('moderateProductPrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">
                    {getCurrencySymbol(order.currency ?? 'cny')}
                  </span>
                </div>
              </InputWrapper>
            </td>
          </tr>
          <tr>
            <td>Комиссия за выкуп</td>
            <td colSpan={2} className="text-end">
              {toMoney(order.orderMarkup, order.currency ?? 'cny')}
            </td>
          </tr>
          <tr>
            <td>Доставка товаров по Китаю</td>
            <td className="text-end">
              {toMoney(order.deliveryPrice, order.currency ?? 'cny')}
            </td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.moderateDeliveryPrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('moderateDeliveryPrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">
                    {getCurrencySymbol(order.currency ?? 'cny')}
                  </span>
                </div>
              </InputWrapper>
            </td>
          </tr>
        </tbody>
      </table>
      <AdditionalService order={order} />
      <hr className="mt-3" />
      <ClientExpensesTable
        total={order.price}
        actual={order.expenses?.client}
        currency={order.currency ?? 'cny'}
      />
      <hr className="mt-3" />
      <CompanyExpensesTable
        total={calculation?.total}
        actual={order.expenses?.company}
        currency={order.currency ?? 'cny'}
        serviceCharge={order.serviceCharge}
        moderateServiceCharge={order.moderateServiceCharge}
      />
      <hr className="mt-3 mb-3" />
      <CompanyProfitTable order={order} />
    </form>
  );
};
