import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  articlePoizonValidation,
  required,
  urlValidation,
} from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { RouterOutput, trpcSwr } from '../../../api/trpc';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import {
  uploadFile,
  uploadMediaInputFiles,
} from '../../../services/file-service';
import { Select } from '../../common/select.component';
import { PRODUCT_CATEGORIES } from './ProductData';
import { getCurrencySymbol } from '../../../utils/common.utils';
import { useNavigate } from 'react-router-dom';
import { Decimal } from 'decimal.js';
import { AddButton } from '../../common/add-button';

import { useGlobalMutate } from '../../../api/useGlobalMutate';

type SizesAndPricesType = {
  size: string;
  originalSize: string;
  priceProduct: string;
  footLength?: string;
};

type Form = {
  disabled: boolean;
  originalId: string;
  article: string;
  link: string;
  name: string;
  category: string;
  subcategory?: string;
  cover: MediaInputValue[];
  files: MediaInputValue[];
  sizesAndPrices: SizesAndPricesType[];
  description: string;
  characteristics: string;
  color: string;
  currency: 'rub' | 'usd' | 'cny';
};

type CardPoizonItemType = RouterOutput['productCardPoizonAdmin']['itemInfo'];

type CardPoizonFileProps = {
  itemInfo?: CardPoizonItemType | null;
};

export const CardPoizonFile = ({ itemInfo }: CardPoizonFileProps) => {
  const navigate = useNavigate();
  const { mutate } = useGlobalMutate();

  const currency = 'cny' as const;

  const defaultItemValues = itemInfo
    ? {
        originalId: itemInfo.originalId,
        article: `${itemInfo.productId}-${itemInfo.modificationId}`,
        link: itemInfo.link,
        name: itemInfo.name,
        category: itemInfo.category,
        subcategory: itemInfo.subcategory || '',
        cover: [
          {
            type: 'exist' as const,
            file: itemInfo.cover,
          },
        ],
        files: itemInfo.files.map((file) => ({
          type: 'exist' as const,
          file,
        })),
        sizesAndPrices: itemInfo.sizesAndPrices.map((item) => ({
          size: item.size,
          originalSize: item.originalSize || '',
          footLength: item.footLength || '',
          priceProduct: item.priceProduct,
        })),
        description: itemInfo.description,
        characteristics: itemInfo.characteristics,
        color: itemInfo.color,
        currency: itemInfo.currency,
      }
    : {
        sizesAndPrices: [
          {
            size: '',
            priceProduct: '',
          },
        ],
      };

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<Form>({
    defaultValues: defaultItemValues,
  });

  const [category] = watch(['category']);
  const selectedCategory = PRODUCT_CATEGORIES.find(
    (categoryItem) => categoryItem.value === category,
  );

  const subcategories = selectedCategory ? selectedCategory.subcategories : [];

  const selectedSizes = selectedCategory ? selectedCategory.sizes : [];
  const selectedOriginalSizes = selectedCategory
    ? selectedCategory.originalSizes
    : [];

  useEffect(() => {
    if (selectedCategory) {
      setValue('subcategory', '');
      setValue(
        'subcategory',
        selectedCategory.subcategories
          ? selectedCategory.subcategories[0].value
          : '',
      );
    }
  }, [selectedCategory]);

  const { trigger: triggerUpdate } =
    trpcSwr.productCardPoizonAdmin.update.useSWRMutation({ fetcher });
  const { trigger: triggerCreate } =
    trpcSwr.productCardPoizonAdmin.create.useSWRMutation({ fetcher });

  const onSubmit = handleSubmit(async (form) => {
    const [productId, modificationId] = form.article.split('-');
    const cover = await uploadMediaInputFiles(form.cover);

    const images = await Promise.all(
      form.files.map(async (item) => {
        const data = item;
        return data.type === 'new'
          ? uploadFile(data.file).then((data) => data.file)
          : Promise.resolve(data.file);
      }),
    );

    const sizesAndPrices = form.sizesAndPrices.map((item) => {
      const footLength = item.footLength
        ? new Decimal(item.footLength).toFixed(1)
        : undefined;
      const priceProduct = new Decimal(item.priceProduct).toFixed(2);

      return {
        size: item.size,
        originalSize: item.originalSize,
        footLength,
        priceProduct,
      };
    });

    const data = {
      originalId: form.originalId,
      productId: productId,
      modificationId: modificationId,
      link: form.link,
      name: form.name,
      category: form.category,
      subcategory: form.subcategory,
      coverId: cover[0].file.id,
      files: images.map((item) => item.id),
      sizesAndPrices: sizesAndPrices,
      description: form.description,
      characteristics: form.characteristics,
      color: form.color,
      currency: currency,
    };
    if (itemInfo) {
      await triggerUpdate({
        id: itemInfo.id,
        data: data,
      });
      mutate();
    } else {
      await triggerCreate(data);
    }
    AlertService.success();
    navigate('/marketing/poizon');
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sizesAndPrices',
  });

  return (
    <>
      <div className="container pb-5" style={{ marginTop: -70 }}>
        <h1 className="mb-4">
          {itemInfo ? 'Редактировать' : 'Добавить'} карточку товара Poizon
        </h1>
        <form onSubmit={onSubmit}>
          <Controller
            control={control}
            name="originalId"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Оригинальный артикул товара"
                error={fieldState.error?.message}
                required
              >
                <input
                  className="form-control "
                  type="text"
                  placeholder=""
                  {...field}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Оригинальный артикул товара на сайте магазина, где будет
                  осуществляться закупка
                </div>
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name={`link`}
            rules={{
              ...required,
              ...urlValidation,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Ссылка на товар"
                className="mt-3"
                error={fieldState.error?.message}
                required
              >
                <input className="form-control" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="article"
            rules={{
              ...required,
              ...articlePoizonValidation,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Внутренний артикул товара"
                error={fieldState.error?.message}
                className="mt-3"
                required
              >
                <input
                  className="form-control "
                  type="text"
                  placeholder="poizonNB574-1"
                  {...field}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Внутренний артикул, предназначенный для отображения в карточке
                  товара ExT. Где poizon - обязательная часть артикула, NB574 -
                  идентификатор модели, а 1 - идентификатор конкретной
                  модификации товара. Товары с совпадающим идентификатором
                  модели будут взаимосвязаны.
                </div>
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="name"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mt-3"
                required
                title="Название товара"
                error={fieldState.error?.message}
              >
                <input className="form-control" type="text" {...field} />
                <div className="fs-14 color-gray-400 mt-1">
                  Отображается в карточке товара
                </div>
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="category"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={PRODUCT_CATEGORIES}
                title="Категория"
                className="mt-3"
                required
                placeholder="Выберите категорию"
                error={fieldState.error?.message}
              />
            )}
          />
          {subcategories && subcategories.length > 0 ? (
            <Controller
              control={control}
              name="subcategory"
              rules={{ ...required }}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  options={subcategories}
                  title="Подкатегория"
                  className="mt-3"
                  required
                  placeholder="Выберите подкатегорию"
                  error={fieldState.error?.message}
                />
              )}
            />
          ) : null}
          <Controller
            control={control}
            name="cover"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                required
                title="Обложка карточки товара"
                error={fieldState.error?.message}
              >
                <MediaInput {...field} className="form-control" />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name={`files`}
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                title="Изображения товара"
                className="mt-3"
                required
                error={fieldState.error?.message}
              >
                <MediaInput
                  {...field}
                  multiple
                  enableClipboardPasteHandler={false}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Размер 1080х1920 px, формат .jpg или .png
                </div>
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="description"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                required
                title="Описание товара"
                error={fieldState.error?.message}
              >
                <textarea
                  className="form-control"
                  style={{ height: '120px' }}
                  {...field}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="characteristics"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                required
                title="Характеристики"
                error={fieldState.error?.message}
              >
                <textarea
                  className="form-control"
                  style={{ height: '120px' }}
                  {...field}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            rules={{ ...required }}
            name={`color`}
            render={({ field, fieldState }) => {
              return (
                <InputWrapper
                  title="Укажите цвет товара"
                  error={fieldState.error?.message}
                  required
                  className="mt-3"
                >
                  <input type="text" className="form-control" {...field} />
                </InputWrapper>
              );
            }}
          />

          <h3 className="mt-3">Цена и размерная сетка</h3>
          {fields.map((field, index) => (
            <div
              className="col-12 mb-3 mt-3 d-flex justify-content-between"
              key={field.id}
            >
              <div className="col-10">
                <div className="rs-text-green">№{index + 1}</div>
                {selectedOriginalSizes && selectedOriginalSizes?.length > 0 ? (
                  <Controller
                    name={`sizesAndPrices.${index}.originalSize`}
                    control={control}
                    rules={{ ...required }}
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        className="mt-3"
                        required
                        error={fieldState.error?.message}
                        options={selectedOriginalSizes}
                        title="Оригинальный размер"
                        placeholder="Укажите размер производителя"
                      />
                    )}
                  />
                ) : null}

                <Controller
                  control={control}
                  rules={{ ...required }}
                  name={`sizesAndPrices.${index}.size`}
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      className="mt-3"
                      required
                      error={fieldState.error?.message}
                      options={selectedSizes}
                      title="Размер"
                      placeholder="Укажите размер"
                    />
                  )}
                />

                {selectedCategory && selectedCategory.value === 'shoes' ? (
                  <Controller
                    control={control}
                    name={`sizesAndPrices.${index}.footLength`}
                    rules={{ ...required }}
                    render={({ field, fieldState }) => (
                      <InputWrapper
                        title="Длина стопы"
                        className="mt-3"
                        required
                        error={fieldState.error?.message}
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="number"
                            min={0}
                            step={0.1}
                            {...field}
                          />
                          <span className="input-group-text">см</span>
                        </div>
                      </InputWrapper>
                    )}
                  />
                ) : null}

                <Controller
                  control={control}
                  name={`sizesAndPrices.${index}.priceProduct`}
                  rules={{ ...required }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title="Цена"
                      className="mt-3"
                      required
                      error={fieldState.error?.message}
                    >
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          {...field}
                        />
                        <span className="input-group-text">
                          {getCurrencySymbol(currency)}
                        </span>
                      </div>
                    </InputWrapper>
                  )}
                />
              </div>
              <div className="d-flex flex-row align-items-center">
                <i
                  className="bi bi-trash p-2 cursor-pointer hover-red"
                  onClick={() => remove(index)}
                />
              </div>
            </div>
          ))}
          <div className="mb-3">
            <AddButton
              onClick={() =>
                append({
                  size: '',
                  originalSize: '',
                  priceProduct: '',
                  footLength: '',
                })
              }
            >
              Добавить размер
            </AddButton>
          </div>

          <div className="col-12 pt-3 mt-5">
            <button
              type="submit"
              className="btn btn-success w-100 mb-5"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : itemInfo ? (
                'Сохранить'
              ) : (
                'Добавить'
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
