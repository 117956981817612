export type ValueLabelPair = {
  value: string;
  label: string;
};

type ProductCategory = {
  value: string;
  label: string;
  subcategories?: ValueLabelPair[];
  sizes: ValueLabelPair[];
  memory?: ValueLabelPair[];
  originalSizes?: ValueLabelPair[];
};

type ShoesSize = {
  label: string;
  value: string;
};

const createSizesShoes = (): ShoesSize[] => {
  const MIN_SIZE = 345;
  const MAX_SIZE = 550;
  const SIZE_INCREMENT = 5;
  const sizes: ShoesSize[] = [];

  for (let size = MIN_SIZE; size <= MAX_SIZE; size += SIZE_INCREMENT) {
    const formattedSize = size / 10;
    sizes.push({ label: String(formattedSize), value: String(formattedSize) });
  }

  return sizes;
};
const createOriginalSizesShoesEU = (): ShoesSize[] => {
  const MIN_SIZE = 330;
  const MAX_SIZE = 550;
  const SIZE_INCREMENT = 5;

  const sizes: ShoesSize[] = [];

  for (let size = MIN_SIZE; size <= MAX_SIZE; size += SIZE_INCREMENT) {
    const euSize = size / 10;
    sizes.push({ label: `EU ${euSize}`, value: `EU${euSize}` });
  }

  return sizes;
};

const SHOES_SIZES: ShoesSize[] = createSizesShoes();
const SHOES_ORIGINAL_SIZES: ShoesSize[] = createOriginalSizesShoesEU();

const ADULT_CLOTHING_SIZES: ValueLabelPair[] = [
  { value: 'xs', label: 'XS' },
  { value: 's', label: 'S' },
  { value: 'm', label: 'M' },
  { value: 'l', label: 'L' },
  { value: 'xl', label: 'XL' },
  { value: 'xxl', label: 'XXL' },
];

const SHOES_SUBCATEGORIES: ValueLabelPair[] = [
  { value: 'sneakers', label: 'Кроссовки' },
];

const CLOTHING_SUBCATEGORIES: ValueLabelPair[] = [
  { value: 'tshirt', label: 'Футболка' },
];

export const PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    value: 'shoes',
    label: 'Обувь',
    subcategories: SHOES_SUBCATEGORIES,
    sizes: SHOES_SIZES,
    originalSizes: SHOES_ORIGINAL_SIZES,
  },
  {
    value: 'clothes',
    label: 'Одежда',
    subcategories: CLOTHING_SUBCATEGORIES,
    sizes: ADULT_CLOTHING_SIZES,
    originalSizes: ADULT_CLOTHING_SIZES,
  },
];
